import axios from "axios";
var SMAMEndpoint = "http://api.rangakamesh.net/DirectMessage";
class APIBag
{
    async postNewSMAM(email,phone,message)
    {
        var name = "PortfolioWebsite"+email;
        var data = await axios.post(SMAMEndpoint, {name,email,phone,message});
        console.log(data)
        return data
    }
}

export default new APIBag();