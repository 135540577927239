import WelcomeBg from '../images/WelcomeBg.jpg'
import MyPicture from '../images/MyPicture.jpeg'
import Siemens from '../images/siemens.png'
import Jci from '../images/jci.png'
import Sherweb from '../images/Sherweb.png'
import SopraSteria from '../images/SopraSteria.png'
import BU from '../images/BU.png'
import PU from '../images/PU2.png'
import OpenChat from '../images/chat.png'


import ProjectsData from "../documents/projects-data.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import './home.component.css';
import '../stylings/language.css';

import { useState, useEffect, useRef } from 'react';

import { Modal, Button } from "react-bootstrap";
import ModalHeader from 'react-bootstrap/ModalHeader'

import APIBag from "../services/portfolio.service";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithubSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'

var cardClassInherits = "card lcard col-lg-5 col-md-12 col-sm-12";

const BackGround1 =
{
  backgroundImage: `url(${WelcomeBg})`,
  height: '100vh',

  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',

  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  flexDirection: 'column'
}

function Chats() {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');


  function handleClose() {
    setEmail('');
    setPhone('');
    setMessage('');
    setShow(false);
  }
  const handleShow = () => setShow(true);


  async function submitMessage(e) {
    e.preventDefault();

    await APIBag.postNewSMAM(
      email,
      phone,
      message
    ).then(
      response => {
      },
      error => {
        console.log(error);
        console.log("Unable to post to function app.")
      }
    )

    setEmail('');
    setPhone('');
    setMessage('');

    setShow(false);
  }

  const setEmailValue = (e) => setEmail(e.currentTarget.value);
  const setPhoneValue = (e) => setPhone(e.currentTarget.value);
  const setMessageValue = (e) => setMessage(e.currentTarget.value);

  return (
    <div>
      <button onClick={handleShow} className="openChatBtnHome">
        <img className="openChatBtn" src={OpenChat} alt="send-me-a-message-icon" /></button>
      <Modal className="modalVariant" show={show} onHide={handleClose} centered>

        <ModalHeader>
          <Modal.Title>Send me a message</Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
        </ModalHeader>

        <Modal.Body>
          <form onSubmit={submitMessage}>
            <div className="mb-3">
              <label for="email" class="form-label">Email address</label>
              <input value={email} onChange={setEmailValue} type="email" class="form-control" id="email" />
            </div>

            <div className="mb-3">
              <label for="phone" class="form-label">Phone Number</label>
              <input value={phone} type="text" onChange={setPhoneValue} class="form-control" id="phone" />
            </div>

            <div className="mb-3">
              <label for="message" class="form-label">Message</label>
              <textarea value={message} class="form-control" onChange={setMessageValue} id="message" />
            </div>
            <div className="form-group">
              <Button variant="danger" onClick={handleClose}>
                Cancel
            </Button>&nbsp;
            <Button type="submit" variant="success">
                Send
            </Button>
            </div>
          </form>
        </Modal.Body>

      </Modal>
    </div>
  );
}

function Contact() {
  return (
    <div>
      <div className="bg-footer">
        <br />
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-8 col-sm-12" style={{ textAlign: 'center' }}>
              <h4>Contact</h4>
              <p style={{ textAlign: 'center' }}>
                <h6>Phone : +1-819-919-3339</h6>
                <h6><a href="mailto:rangakamesh@hotmail.com">rangakamesh@hotmail.com</a></h6>
              </p>
            </div>

            <div className="col-lg-4 col-md-8 col-sm-12">
              <h4>Lives at</h4>
              <p style={{ textAlign: 'center' }}>
                <h6>Ottawa, Ontario, Canada.</h6>
              </p>
            </div>

            <div className="col-lg-4 col-md-8 col-sm-12">
              <h4>Telnet Hotline</h4>
              <p style={{ textAlign: 'center' }}>
                <h6>telnet telnet.rangakamesh.net 16996</h6>
              </p>
            </div>
          </div>
        </div>
        <div className="row">

          <div className="col-lg-12" style={{ textAlign: 'center' }}>

            <a href="https://github.com/rangakamesh" target="none" style={{ color: 'white' }}><FontAwesomeIcon icon={faGithubSquare} style={{ width: '45px', height: '45px' }} /></a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a href="https://www.linkedin.com/in/rangakameswaran/" target="none" style={{ color: 'white' }}><FontAwesomeIcon icon={faLinkedin} style={{ width: '45px', height: '45px' }} /></a>

          </div>

        </div>
      </div>
    </div>
  );
}

function Projects() {
  var containerStyle;

  if (window.innerWidth < 1000) {
    containerStyle = {}
  }
  else {
    containerStyle = { paddingLeft: '11%', paddingRight: '11%' };
  }

  var projectCards = ProjectsData.map((tech) => (
    <div key={tech.name} className="card dcard">
      <h4 className="card-title">{tech.name}</h4>
      {tech.langages}
      {tech.description}
    </div>
  ))

  var projectCardsArranged = [];

  for (var i = 0; i < projectCards.length; i++) {

    var colCards = [];

    colCards.push(
      <div key={i} className="col-lg-4 col-md-12 col-sm-12">
        {projectCards[i]}
      </div>
    );

    i = i + 1;

    colCards.push(
      <div key={i} className="col-lg-4 col-md-12 col-sm-12">
        {projectCards[i]}
      </div>
    );

    i = i + 1;

    colCards.push(
      <div key={i} className="col-lg-4 col-md-12 col-sm-12">
        {projectCards[i]}
      </div>
    );
    var rowed = <div><div className="row">{colCards}</div>&nbsp;</div>;
    projectCardsArranged.push(rowed);
  }

  return (
    <div className="container" style={containerStyle}>
      <div className="row">
        <h2>Projects </h2>
        <hr />
      </div>
      {projectCardsArranged}
      <br />
    </div>
  )
}

function Profession() {
  var containerStyle;

  console.log("Window Width : " + window.innerWidth)
  if (window.innerWidth < 1000) {
    containerStyle = {}
  }
  else {
    containerStyle = { paddingLeft: '11%', paddingRight: '11%' };
  }

  return(
    <div className="container" style={containerStyle}>
      <div className="row justify-content-md-center">
        <h2>Profession</h2>
        <h4 className="tx-4">Software Developer</h4>
        <hr />
      </div>

      <div className="row justify-content-md-center">

        <div className="row gx-5">

          <div className={cardClassInherits}>

            <h4 className="card-title">
              <div className='logo-container'>
                <img src={Siemens} alt="Kameswaran Rangasamy" style={{height: "100%", width: "100%", objectFit: 'contain'}}/>
                </div>
            </h4>

            <h5 className="card-subtitle">Software developer</h5>
            <h6>(January 2022 - Current)</h6>
            <p>Develop and maintain a large-scale production system of the epoc Blood analysis system using C# ASP.NET and Azure. Design, test and document features under development.</p>
          </div>

          <div className={cardClassInherits}>

            <h4 className="card-title">
              <div className='logo-container'>
                <img src={Jci} alt="Kameswaran Rangasamy" style={{height: "100%", width: "100%", objectFit: 'contain'}}/>
              </div>
            </h4>

            <h5 className="card-subtitle">Software developer</h5>
            <h6>(July 2021 - December 2021)</h6>
            <p>Develop windows services that interact with Fire Detection systems using C# .NET and configure the Fire Detection network. Develop and Manage CI/CD pipelines for new services. Design and document features under development.</p>
          </div>

          <div className={cardClassInherits}>

            <h4 className="card-title">
              <div className='logo-container'>
                <img src={Sherweb} alt="Kameswaran Rangasamy" style={{height: "100%", width: "100%", objectFit: 'contain'}}/>
              </div>
            </h4>

            {/* <h4 className="card-title">Sherweb</h4> */}
            <h5 className="card-subtitle">M365 Technical Advisor</h5>
            <h6>(July 2020 - June 2021)</h6>
            <p>Assist client deploy, manage, and automate their Microsoft 365 SaaS and Azure AD.</p>
          </div>

          <div className={cardClassInherits}>
          <h4 className="card-title">
              <div className='logo-container'>
                <img src={SopraSteria} alt="Kameswaran Rangasamy" style={{height: "100%", width: "100%", objectFit: 'contain'}}/>
              </div>
            </h4>

            {/* <h4 className="card-title">Sopra Steria Inc.</h4> */}
            <h5 className="card-subtitle">Software Engineer</h5>
            <h6>(July 2017 - July 2019)</h6>
            <p>Was part of a SCRUM enhancing and supporting a warehouse management system running in (C, UNIX) and (C#, .NET, MSSQL).</p>
          </div>
        </div>

      </div>
    </div>
  );
}

function Education() {
  var containerStyle;

  console.log("Window Width : " + window.innerWidth)
  if (window.innerWidth < 1000) {
    containerStyle = {}
  }
  else {
    containerStyle = { paddingLeft: '11%', paddingRight: '11%' };
  }

  return(
    <div className="container" style={containerStyle}>
      <div className="row justify-content-md-center">
        <h2>Education</h2>
        <h4 className="tx-4">Computer science and Engineering</h4>
        <hr />
      </div>

      <div className="row justify-content-md-center">
        <div className="row gx-5">
          
          <div className={cardClassInherits} style={{textAlign:'left'}}>

            <div className='logo-container'>
              <img src={PU} alt="Kameswaran Rangasamy" style={{height: "100%", width: "100%", objectFit: 'contain'}}/>
            </div>

            <h5>Pondicherry University</h5>

            <h5 className="card-subtitle">BTech - C.S. and Eng.</h5>
            <h6>(August 2013 - April 2017)</h6>
          </div>

          <div className={cardClassInherits}>
            <h4 className="card-title">
                <div className='logo-container'>
                  <img src={BU} alt="Kameswaran Rangasamy" style={{height: "100%", width: "100%", objectFit: 'contain'}}/>
                </div>
            </h4>

            <h5>Bishop's University</h5>

            <h5 className="card-subtitle">MSc - Computer Science</h5>
            <h6>(August 2019 - April 2021)</h6>
            {/* <p>Was part of a SCRUM enhancing and supporting a warehouse management system running in C and UNIX mainly.</p> */}
          </div>


        </div>
      </div>
    </div>
  );
}

function SideNavbar(props) {
  var show = { visibility: props.show }
  return (
    <nav id="sideNav" className="side-nav" style={show}>
      <nav className="nav" style={show}>
        <ul className="list-group">
          <li key="Home" className="list-group-item"><a className="nav-link" href="#home">Home</a></li>
          <li key="About" className="list-group-item"><a className="nav-link" href="#about">About</a></li>
          <li key="Pro" className="list-group-item"><a className="nav-link" href="#profession">Profession</a></li>
          <li key="Education" className="list-group-item"><a className="nav-link" href="#education">Education</a></li>
          <li key="Projects" className="list-group-item"><a className="nav-link" href="#projects">Projects</a></li>
          <li key="Contact" className="list-group-item"><a className="nav-link" href="#contact">Contact</a></li>
        </ul>
      </nav>
    </nav>
  )
}

function About() {
  var containerStyle;

  if (window.innerWidth < 1000) {
    containerStyle = {}
  }
  else {
    containerStyle = { paddingLeft: '11%', paddingRight: '11%' };
  }

  return (
    <div className="container" style={containerStyle}>

      <div className="row">
        <h2>Depiction</h2>
        <h4 className="tx-2">Make stuff happen.</h4>
        <hr />
      </div>


      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-12 col-sm-12">
          <h2 style={{ textAlign: 'left' }}>I am,</h2>
          <span className="tx-2 justify-content-md-center" style={{ fontFamily: 'sans', textAlign: 'left', }}>
            <p>
              &emsp;&emsp;&emsp;Kameswaran Rangasamy from India.<br />
              <br />A recent Computer science graduate from Bishop's University, Sherbrooke.👨‍🎓<br />
              <br /> I prompt my finest replies at "rangakamesh@hotmail.com".📫
              </p>
          </span>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-8">
          <img src={MyPicture} alt="Kameswaran Rangasamy" className="img-fluid rounded-circle text-center" />
        </div>

        <div className="col-lg-4 col-md-12 col-sm-12">
          <h2 style={{ textAlign: "left" }}>I'm a...</h2>
          <span className="tx-2 justify-content-md-center" style={{ fontFamily: 'sans', textAlign: 'left' }}>
            <p>
              &emsp;&emsp;&emsp;&emsp;&emsp; tenacious developer who codes with headphones over the head and coherence inside.<br />
              <br />Easy to understand and a cut above finer quality pleasure me than zero errors ❤️.<br />
              <br />Rinzler is my root and he fights for the users. I issue, "kill -9" and consider them "Derezzed". I make calls to the creator (kernel), for all my requests. Love Tron Legacy ⚡
              </p>
          </span>
        </div>
      </div>
    </div>
  )
}

function Home() {
  const headerRef = useRef('');
  const [sideNavVisibility, SetSideNavVisibility] = useState("hidden");

  function handleScroll(elTopOffset, elHeight) {
    if (window.pageYOffset > (elTopOffset + elHeight - 200) && window.innerWidth > 1100) {
      SetSideNavVisibility("visible");
    } else {
      SetSideNavVisibility("hidden");
    }
  }

  var sideNav;
  if (sideNavVisibility === "visible") {
    sideNav = <SideNavbar show="visible" />
  }
  else { sideNav = <SideNavbar show="hidden" /> }

  useEffect(
    () => {
      
      var header = headerRef.current.getBoundingClientRect();

      const handleScrollEvent = () => {
        handleScroll(header.top, header.height)
      }

      window.addEventListener('scroll', handleScrollEvent);

      return () => {
        window.removeEventListener('scroll', handleScrollEvent);
      };
    }, []
  );

  return (
    <div className="container-fluid" data-spy="scroll" data-target="#sideNav" data-offset="0">

      {sideNav}

      <div key="home" id="home" className='jumbotron-fluid' style={BackGround1} ref={headerRef}>
        <div class="container-fluid mx-auto text-center">
          {/* <h4 id="typedtext">This website is under construction. Please visit back later. Thank you.</h4> */}
          <h3>
            <br /><br />Kameswaran Rangasamy<br />
            <hr style={{ width: '30%', height: '2px', marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#f5f6f7' }} />
          </h3>
          <h5>Collective Portfolio<br /><br /><br /><br /><br /></h5>
        </div>
      </div>

      <div key="about" id="about" className="container-fluid bg-2 text-center div2">
        <About />
      </div>

      <div key="profession" id="profession" className="container-fluid bg-4 text-center div2">
        <Profession />
      </div>

      <div key="education" id="education" className="container-fluid bg-2 text-center div2">
        <Education />
      </div>

      <div key="projects" id="projects" className="container-fluid bg-4 text-center div2">
        <Projects />
      </div>

      <div key="contact" id="contact" className="container-fluid text-center">
        <Contact />
      </div>

      <Chats />

      {/* <div id="Bot" className="container-fluid text-center">
      <Bot />
      </div> */}

    </div>

  );
}

export default Home;