import '../stylings/language.css';
var ProjectsData=
[
{
    name:"Covid19 Data Serve",
    langages:                
            <div>
                <span className="badge badge-csharp">C#</span>&nbsp;
                <span className="badge badge-python">Python</span>&nbsp;
                <span className="badge badge-livescript">React.js</span>&nbsp;
                <span className="badge badge-csharp">Mongo DB</span>&nbsp;
            </div>,
    description: <p>API service, serving clean covid19 statistical data. Check it out <a href="https://covidserve.azurewebsites.net" target="none">here</a>.</p>,
},

{
    name:"Sentiment Analyzer",
    langages:                
            <div>
                <span className="badge badge-csharp">C#</span>&nbsp;
                <span className="badge badge-livescript">React.js</span>&nbsp;
                <span className="badge badge-csharp">Mongo DB</span>&nbsp;
                <span className="badge badge-python">Twitter</span>&nbsp;
                <span className="badge badge-java">ML.NET</span>&nbsp;
            </div>,
    description: <p>A sentiment analyzer, which can detect the sentiment of a text, however long it is. Check it out <a href="https://lemon-rock-0aa346f0f.azurestaticapps.net/" target="none">here</a>.</p> 
},

{
    name:"Bulletin Board Server",
    langages:                
            <div>
                <span className="badge badge-cplusplus">C++</span>&nbsp;
            </div>,
    description: <p>A pre-allocated, multi-threaded, scalable - bulletin board server. Reach out to my personal BBS at telnet 2.tcp.ngrok.io 19915</p>
},

{
    name:"Heterogeneous Human Activity Recognition",
    langages:                
            <div>
                <span className="badge badge-python">Python</span>&nbsp;
                <span className="badge badge-ruby">PyTorch</span>&nbsp;
            </div>,
    description: <p>Comparision of multiple neural networks to predict human activities based on sensor readings.</p>
},


{
    name:"Loan Default Prediction",
    langages:                
            <div>
                <span className="badge badge-python">Python</span>&nbsp;
                <span className="badge badge-java">scikit learn</span>&nbsp;
            </div>,
    description: "Machine learning model to predict if a customer will default their loan based on their transaction and load details."
},

{
    name:"Medical image segmentation",
    langages:                
            <div>
                <span className="badge badge-python">python</span>&nbsp;
                <span className="badge badge-ruby">SimpleITK</span>&nbsp;
            </div>,
    description: <p>A tool to create a segmentation mask of parts or abnormalities on an organ, based on MRI scans. Speicialized for the grey matter of the human brain.</p> 
},

{
    name:"Medical tracking",
    langages:                
            <div>
                <span className="badge badge-python">python</span>&nbsp;
                <span className="badge badge-ruby">SimpleITK</span>&nbsp;
                <span className="badge badge-julia">OpenCV</span>&nbsp;
            </div>,
    description: <p>A computer-aided intervention tool to track a segment of a scan across multiple moving images.</p> 
},

]

export default ProjectsData;